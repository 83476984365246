import { Form, useSearchParams } from "@remix-run/react";
import { Search } from "lucide-react";
import {
  useRef,
  type DetailedHTMLProps,
  type InputHTMLAttributes,
} from "react";
import { cn } from "~/lib/utils";
import { inputVariants } from "./Input";

function throttle<T>(fn: (...params: T[]) => void, wait: number) {
  let time = Date.now();
  return (...params: T[]) => {
    if (time + wait - Date.now() < 0) {
      fn(...params);
      time = Date.now();
    }
  };
}

export function SearchInput({
  className,
  ...props
}: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  const [, setParams] = useSearchParams();

  const throttledSetParams = useRef(
    throttle((value: string) => {
      setParams({ q: value });
    }, 500)
  );

  return (
    <Form method="get" className="px-8">
      <label
        className={cn(
          inputVariants({ inputSize: "lg", variant: "search" }),
          "rounded-full flex items-center gap-4 cursor-text",
          className
        )}
      >
        <span className="sr-only">Search for Recipes</span>
        <Search />
        <input
          name="q"
          type="search"
          placeholder="What would you like to search for?"
          {...props}
          className="border-0 bg-transparent outline-none w-full"
          onChange={(e) => {
            const value = e.target.value;
            throttledSetParams.current(value);
          }}
        />
      </label>
    </Form>
  );
}
