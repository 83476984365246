import {
  type LoaderFunctionArgs,
  type ActionFunctionArgs,
} from "@remix-run/node";
import { useLoaderData, type ClientLoaderFunctionArgs } from "@remix-run/react";
import { SearchInput } from "~/components/ui/SearchInput";
import { db } from "~/lib/db.server";
import { RecipeCard, recipeCardSelect } from "~/components/RecipeCard";
import { Categories } from "~/components/Categories";
import { edgeAuthenticator } from "~/lib/auth.edge.server";
import { nanoid } from "~/lib/nanoid";
import { getCommunity } from "~/lib/getCommunity";
import { sessionStorage } from "~/lib/session.server";
import { rep } from "~/utils/replicache.client";
import type { Ingredient, MealPlan, Recipe } from "kysely-codegen";
import { matchSorter } from "match-sorter";

export const clientAction = async ({ request }: ActionFunctionArgs) => {
  const user = window.USER;

  const formData = await request.formData();
  const action = formData.get("action") as string;
  const recipe = formData.get("recipe") as string;
  const mealPlanId = formData.get("mealPlanId") as string;
  if (!user)
    throw new Response("", { status: 307, headers: { Location: "/login" } });
  const orgId = user.org;
  if (!orgId) throw new Error("No orgId");

  if (action === "removeMealPlan") {
    if (!mealPlanId) throw new Error("No mealPlanId");

    await rep.mutate.deleteMealPlan({
      mealPlanId,
      orgId,
    });
  }
  if (action === "mealPlan") {
    await rep.mutate.addMealPlan({
      mealPlanId: nanoid(),
      orgId,
      recipeId: recipe,
      userId: user.id,
    });
  }

  return {};
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await edgeAuthenticator.isAuthenticated(request);

  const community = await getCommunity(
    await sessionStorage.getSession(request.headers.get("Cookie")),
    user?.id
  );

  const params = new URL(request.url).searchParams;
  let search = params.get("q");
  const categories = await db
    .selectFrom("Category")
    .orderBy("sort", "asc")
    .selectAll()
    .execute();

  const recipes = await db
    .selectFrom("Recipe")
    .leftJoin("MealPlan", (join) =>
      join
        .onRef("MealPlan.recipeId", "=", "Recipe.slug")
        .on("MealPlan.cookedDate", "is", null)
        .on("MealPlan.deleted", "=", 0)
        .on("MealPlan.orgId", "=", community)
    )
    .where("Recipe.published", "=", 1)
    .where((eb) =>
      eb.or([eb("Recipe.public", "=", 1), eb("Recipe.orgId", "=", community)])
    )
    .select([...recipeCardSelect])
    .orderBy("Recipe.title", "asc")
    .execute();

  const sortedRecipes = search
    ? matchSorter(recipes, search, {
        keys: ["title", "description", "ingredients"],
      })
    : recipes;

  return {
    recipes: sortedRecipes,
    categories,
    search: search || "",
    isLoggedIn: !!user,
  };
};

export const clientLoader = async ({ request }: ClientLoaderFunctionArgs) => {
  const user = window.USER || null;
  const recipes = await rep.query(async (tx) => {
    const recipes = (await tx
      .scan({ prefix: "Recipe/" })
      .toArray()) as unknown as Array<
      Omit<Recipe, "createdAt"> & { createdAt: string }
    >;
    const ingredients = (await tx
      .scan({ prefix: "Ingredient/" })
      .toArray()) as unknown as Array<Ingredient>;

    const mealPlans = (await tx
      .scan({ prefix: `MealPlan/${user?.org}` })
      .toArray()) as any as MealPlan[];

    recipes.sort(({ title: a }, { title: b }) =>
      a.toLowerCase() > b.toLowerCase() ? 1 : -1
    );

    const plannedRecipes = mealPlans.filter(
      ({ cookedDate }) => cookedDate === null
    );

    // TODO: Add ingredients and steps to recipe
    // so we can search over them.
    return Promise.all(
      recipes.map(async (recipe, i) => {
        const stepIngredients = (
          (await tx
            .scan({ prefix: `RecipeStepIngredient/${recipe.slug}/` })
            .toArray()) as any as Array<{ ingredientId: string }>
        ).map(
          ({ ingredientId }) =>
            ingredients.find((i) => i.slug === ingredientId)?.title
        );
        return {
          ...recipe,
          ingredients: stepIngredients.join(", "),
          mealPlan: plannedRecipes.find(
            ({ recipeId }) => recipeId === recipe.slug
          )?.id,
        };
      })
    );
  });

  const categories = await rep.query(async (tx) => {
    const categories = (await tx
      .scan({ prefix: "Category/" })
      .toArray()) as unknown as Array<{
      slug: string;
      title: string;
      description: string;
      sort: number;
    }>;
    return categories.sort(({ sort: a }, { sort: b }) => a - b);
  });
  const searchParams = new URL(request.url).searchParams;
  const search = searchParams.get("q");

  const sortedRecipes = search
    ? matchSorter(recipes, search, {
        keys: ["title", "description", "ingredients"],
      })
    : recipes;

  return {
    recipes: sortedRecipes,
    categories,
    search: search || "",
    isLoggedIn: !!user,
  };
};

export default function Index() {
  const data = useLoaderData<typeof loader>();
  return (
    <>
      <SearchInput className="max-w-3xl mx-auto" defaultValue={data.search} />
      <Categories categories={data.categories} />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-start justify-items-center gap-4 sm:gap-8 w-full px-4 sm:pr-8 mt-16">
        {data.recipes.map((recipe, index) => (
          <RecipeCard
            key={recipe.slug}
            {...recipe}
            index={index}
            isLoggedIn={data.isLoggedIn}
          />
        ))}
      </div>
    </>
  );
}
